import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, IconButton, InputAdornment, MenuItem, Switch, TextField, useMediaQuery, useTheme } from '@mui/material';
import { DeviceFormProps } from '../../@types/Props';
import { useFormik } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { TextMaskFullCellPhone } from '../Common/Masks';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { speedFromKnots } from '../../util/util';

type CategoryType = {
  value: string;
  label: string;
};

const categories = [
  {
    value: 'animal',
    label: 'Animal'
  },
  {
    value: 'bicycle',
    label: 'Bicicleta'
  },
  {
    value: 'boat',
    label: 'Barco'
  },
  {
    value: 'bus',
    label: 'Ônibus'
  },
  {
    value: 'car',
    label: 'Carro',
  },
  {
    value: 'crane',
    label: 'Guindaste'
  },
  {
    value: 'helicopter',
    label: 'Helicóptero'
  },
  {
    value: 'motorcycle',
    label: 'Motocicleta'
  },
  {
    value: 'offroad',
    label: 'Offroad'
  },
  {
    value: 'person',
    label: 'Pessoa'
  },
  {
    value: 'pickup',
    label: 'Pickup'
  },
  {
    value: 'plane',
    label: 'Avião'
  },
  {
    value: 'scooter',
    label: 'Lambreta'
  },
  {
    value: 'ship',
    label: 'Navio'
  },
  {
    value: 'tractor',
    label: 'Trator'
  },
  {
    value: 'train',
    label: 'Trem'
  },
  {
    value: 'tram',
    label: 'Bonde'
  },
  {
    value: 'trolleybus',
    label: 'Ônibus elétrico'
  },
  {
    value: 'truck',
    label: 'Caminhão',
  },
  {
    value: 'van',
    label: 'Van'
  },
];

const models = [
  {
    value: 'E3+',
    label: 'E3+',
  },
  {
    value: 'TK 303',
    label: 'TK 303',
  },
  {
    value: 'TK 311C',
    label: 'TK 311C',
  },
  {
    value: 'J14',
    label: 'J14',
  },
  {
    value: 'J16',
    label: 'J16',
  },
  {
    value: 'J16 4G',
    label: 'J16 4G',
  },
  {
    value: 'OneFrottus',
    label: 'OneFrottus',
  },
  {
    value: 'SR411 MINI',
    label: 'SR411 MINI',
  },
  // {
  //   value: 'SigFox',
  //   label: 'SigFox',
  // },
  // {
  //   value: 'VTR300',
  //   label: 'VTR300',
  // },
  // {
  //   value: 'COBAN',
  //   label: 'COBAN',
  // },
  // {
  //   value: 'Queclink',
  //   label: 'Queclink',
  // },
  // {
  //   value: 'STG',
  //   label: 'STG',
  // },
  // {
  //   value: 'GlobalStar',
  //   label: 'GlobalStar',
  // },
  // {
  //   value: 'Multiportal',
  //   label: 'Multiportal',
  // },
  // {
  //   value: 'Topflytech',
  //   label: 'Topflytech',
  // },
  // {
  //   value: 'STCell',
  //   label: 'STCell',
  // },
  {
    value: 'Suntech',
    label: 'Suntech',
  },
  // {
  //   value: 'MaxTrack',
  //   label: 'MaxTrack',
  // },
  // {
  //   value: 'BWS',
  //   label: 'BWS',
  // },
  {
    value: 'Concox CRX3 Mini',
    label: 'Concox CRX3 Mini',
  },
  // {
  //   value: 'Concox GT',
  //   label: 'Concox GT',
  // },
  // {
  //   value: 'SigFox',
  //   label: 'SigFox',
  // },
  // {
  //   value: 'GT06',
  //   label: 'GT06',
  // },
  {
    value: 'LV12',
    label: 'LV12',
  },
  {
    value: 'LV12 4G',
    label: 'LV12 4G',
  },
  {
    value: 'NT20',
    label: 'NT20',
  },
  {
    value: 'Oneblock 2G',
    label: 'Oneblock 2G',
  },
  {
    value: 'Oneblock 4G',
    label: 'Oneblock 4G',
  },
];

const DeviceForm = ({title, subtitle, device, opened, readonly, close, onSubmit}: DeviceFormProps) => {
  const groups = useSelector((state: RootState) => state.group.items);
  const groupsList = Object.entries(groups).map(x => x[1]);
  
  const emptyValues = {
    name: "",
    uniqueId: "",
    groupId: 0,
    phone: "",
    model: "",
    category: "",
    //attributes
    placa: "",
    modelo: "",
    ano: "",
    comandoBloqueio: false,
    speedLimit: "",
    comodato: false,
    deviceImage: "",
  };
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: yup.object({
      name: yup.string().required("Campo obrigatório."),
      uniqueId: yup.string().required("Campo obrigatório."),
      groupId: yup.number().required("Campo obrigatório."),
      phone: yup.string().required("Campo obrigatório."),
      model: yup.string().required("Campo obrigatório."),
      category: yup.string().required("Campo obrigatório."),
      placa: yup.string().required("Campo obrigatório."),
      modelo: yup.string().required("Campo obrigatório."),
      ano: yup.string().required("Campo obrigatório."),
      speedLimit: yup.string().notRequired(),
    }),
    onSubmit: onSubmit,
  });

  const compSortCategory = (a: CategoryType,b: CategoryType) => {
    if (a.label < b.label)
      return -1;
    if (a.label > b.label)
      return 1;
    
    return 0;
  }

  const onDialogClose = (event: object, reason: string) => {
    if (reason !== "backdropClick") close();
  }

  const onCancelButtonClicked = () => {
    formik.resetForm({ values: emptyValues })
    formik.setErrors({});
    close();
  }

  useEffect(() => {
    if (device !== null && device !== undefined) {
      formik.setValues({
        name: device.name ?? '',
        uniqueId: device.uniqueId ?? '',
        groupId: device.groupId,
        phone: device.phone ?? '',
        model: device.model ?? '',
        category: device.category ?? '',
        placa: device.attributes.placa ?? '',
        modelo: device.attributes.modelo ?? '',
        ano: device.attributes.ano.toString(),
        comandoBloqueio: device.attributes.comandoBloqueio,
        speedLimit: speedFromKnots(device.attributes.speedLimit, 'kmh').toString(),
        comodato: device.attributes.comodato,
        deviceImage: device?.attributes?.deviceImage ?? ''
      })
    }
  }, [device]);

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      open={opened}
      onClose={onDialogClose}
      aria-labelledby="responsive-dialog-title"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="responsive-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {subtitle}
          </DialogContentText>
          
          <div className='flex flex-col py-4'>
            <div className='flex flex-col md:flex-row'>
              <TextField
                autoCapitalize='true'
                fullWidth
                size='small'
                variant='filled'
                label="Nome"
                id="name"
                name="name"
                disabled={readonly}
                value={formik.values.name.toUpperCase()}
                onChange={(event => formik.setValues({...formik.values, name: event.target.value.toUpperCase()}))}
                //onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className='flex flex-col md:flex-row'>
              <TextField
                select
                className='w-full md:w-1/3'
                size='small'
                variant="filled"
                label="Modelo Rastreador"
                id="model"
                name="model"
                disabled={readonly}
                value={formik.values.model}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.model && Boolean(formik.errors.model)}
                helperText={formik.touched.model && formik.errors.model}
              >
                {
                  models.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                }
              </TextField>
              <TextField
                className='w-full md:w-1/3'
                size='small'
                variant="filled"
                label="Código Rastreador"
                id="uniqueId"
                name="uniqueId"
                disabled={readonly}
                value={formik.values.uniqueId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.uniqueId && Boolean(formik.errors.uniqueId)}
                helperText={formik.touched.uniqueId && formik.errors.uniqueId}
              />
              <TextField
                className='w-full md:w-1/3'
                size='small'
                variant="filled"
                label="Nº Chip Rastreador"
                id="phone"
                name="phone"
                disabled={readonly}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </div>
            <div className='flex flex-col md:flex-row'>
              <TextField
                className='w-full md:w-1/3 '
                size='small'
                variant="filled"
                label="Placa"
                id="placa"
                name="placa"
                disabled={readonly}
                value={formik.values.placa.toUpperCase()}
                onChange={(event => formik.setValues({...formik.values, placa: event.target.value.toUpperCase()}))}
                //onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.placa && Boolean(formik.errors.placa)}
                helperText={formik.touched.placa && formik.errors.placa}
              />
              <TextField
                select
                className='w-full md:w-1/3 '
                size='small'
                variant="filled"
                label="Grupo"
                id="groupId"
                name="groupId"
                disabled={readonly}
                value={formik.values.groupId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.groupId && Boolean(formik.errors.groupId)}
                helperText={formik.touched.groupId && formik.errors.groupId}
              >
                {
                  groupsList != undefined && groupsList != null ?
                  groupsList.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                  ))
                : null
                }
              </TextField>
              <TextField
                className='w-full md:w-1/3 '
                size='small'
                variant="filled"
                label="Modelo"
                id="modelo"
                name="modelo"
                disabled={readonly}
                value={formik.values.modelo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                helperText={formik.touched.modelo && formik.errors.modelo}
              />
            </div>
            <div className='flex flex-col md:flex-row pb-2'>
              <TextField
                select
                className='w-full md:w-1/3 '
                size='small'
                variant="filled"
                label="Categoria"
                id="category"
                name="category"
                disabled={readonly}
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.category && Boolean(formik.errors.category)}
                helperText={formik.touched.category && formik.errors.category}
              >
                {
                  categories.sort(compSortCategory).map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                }
              </TextField>
              <TextField
                className='w-full md:w-1/3 '
                size='small'
                variant="filled"
                label="Ano"
                id="ano"
                name="ano"
                disabled={readonly}
                value={formik.values.ano}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.ano && Boolean(formik.errors.ano)}
                helperText={formik.touched.ano && formik.errors.ano}
              />
              <TextField
                className='w-full md:w-1/3 '
                size='small'
                variant="filled"
                label="Limite Velocidade (km/h)"
                id="speedLimit"
                name="speedLimit"
                disabled={readonly}
                value={formik.values.speedLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.speedLimit && Boolean(formik.errors.speedLimit)}
                helperText={formik.touched.speedLimit && formik.errors.speedLimit}
              />
            </div>
            <div className='flex flex-col md:flex-row pb-2'>
              <FormGroup>
                <FormControlLabel disabled={readonly} control={<Switch checked={formik.values.comandoBloqueio} onClick={() => formik.setValues({...formik.values, comandoBloqueio: !formik.values.comandoBloqueio})} />} label="Comando de bloqueio ativo" />
                <FormControlLabel disabled={readonly} control={<Switch checked={formik.values.comodato} onClick={() => formik.setValues({...formik.values, comodato: !formik.values.comodato})} />} label="Rastreador em comodato" />
              </FormGroup>
            </div>
          </div>
          
        </DialogContent>
        <DialogActions>
          {
            !readonly ?
            <Button variant='text' color='error' onClick={onCancelButtonClicked}>
              CANCELAR
            </Button> : null
          }
          <Button type='submit' onClick={() => {}} variant='contained' color='primary' autoFocus>
            {readonly ? 'FECHAR' : device ? 'ATUALIZAR' : 'SALVAR'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeviceForm;