import { BaseResponseType, UploadFile } from "../@types/Responses";
import { deleteOptions } from "./common";

const endpoint = `${process.env.REACT_APP_API_FOLDER}/bills`;

const upload = async (uploadFile: UploadFile) : Promise<BaseResponseType>=> {
  const url = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`;
  const formData = new FormData();
  formData.append('file', uploadFile.file);
  formData.append('fileName', uploadFile.file.name);
  const config = {
    method: 'POST',
    body: formData,
    // headers: {
    //   'content-type': 'multipart/form-data',
    // },
  };

  const response = await fetch(url, config);
  return response.json();
}

const deleteBill = async (fileName: string) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/${fileName}`, deleteOptions());
  return response.json();
}

export const bill = {
  upload,
  deleteBill,
};