import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable, MRT_SortingState, MRT_Virtualizer } from 'material-react-table';
import type { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { Position } from '../../@types/Position';
import { PositionTableProps } from '../../@types/Props';
import TableTopToolbarAction from '../Common/TableTopToolbarAction';
import TableContainer from '../Common/TableContainer';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { getTimeDiff, speedFromKnots } from '../../util/util';
import dayjs from 'dayjs';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const PositionTable = memo(({positions, actions}: PositionTableProps) => {
  const devices = useSelector((state: RootState) => state.device.items);
  const [data, setData] = useState<Position[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'deviceTime', desc: false, }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  const columns = useMemo<MRT_ColumnDef<Position>[]>(
    () => [
      {
        accessorKey: 'row.deviceId', //simple recommended way to define a column
        accessorFn: (row) => devices[row.deviceId] ? `${devices[row.deviceId].attributes.placa} - ${devices[row.deviceId].name}` : '',
        header: 'Veículo',
        size: 50,
      },
      {
        accessorFn: (row) => dayjs(row.fixTime).format('DD/MM/YYYY HH:mm:ss'),
        accessorKey: 'fixTime', //id required if you use accessorFn instead of accessorKey
        header: 'Horário GPS',
        size: 50,
      },
      {
        accessorFn: (row) => dayjs(row.deviceTime).format('DD/MM/YYYY HH:mm:ss'),
        accessorKey: 'serverTime', //id required if you use accessorFn instead of accessorKey
        header: 'Horário Servidor',
        size: 50,
      },
      // {
      //   accessorKey: 'fixTime',
      //   header: 'fixTime',
      //   size: 55,
      // },
      // {
      //   accessorKey: 'serverTime',
      //   header: 'serverTime',
      //   size: 55,
      // },
      {
        accessorKey: 'latitude',
        accessorFn: (row) => `${row.latitude.toFixed(6)}°`,
        header: 'Latitude',
        size: 50,
      },
      {
        accessorKey: 'longitude',
        accessorFn: (row) => `${row.longitude.toFixed(6)}°`,
        header: 'Longitude',
        size: 50,
      },
      {
        accessorFn: (row) => `${speedFromKnots(row.speed, 'kmh')} km/h`,
        accessorKey: 'speed',
        header: 'Velocidade',
        size: 50,
      },
      {
        accessorKey: 'address',
        header: 'Endereço',
        size: 50,
      },
      {
        accessorFn: (row) => row.attributes.sat ?? 'Sem satélite',
        accessorKey: 'attributes.sat',
        header: 'Satélites',
        size: 50,
      },
      // {
      //   accessorFn: (row) => row.position?.valid ? 'Com GPS' : 'Sem GPS',
      //   accessorKey: 'position.valid',
      //   header: 'Status',
      //   size: 50,
      // },
      // {
      //   accessorKey: 'lastUpdate',
      //   accessorFn: (row) => getTimeDiff(row.lastUpdate, true),
      //   header: 'Última Comunicação',
      //   size: 50,
      // }
    ],
    [],
  );

  const handleExportCsvRows = (table: MRT_TableInstance<Position>) => {
    let rows = table.getFilteredRowModel().rows
    let columnHeaders = new Array<string>();
    rows[0].getAllCells().forEach(cel => {
      if (cel.column.getIsVisible() && cel.column.columnDef.header !== "Actions")
        columnHeaders.push(cel.column.columnDef.header as string)
    })

    const rowData = rows.map((row) => {
      return {
        "Veículo": table.getColumn(columns[0].accessorKey as string).getIsVisible() ? `${devices[row.original.deviceId].attributes.placa} - ${devices[row.original.deviceId].name}` : null,
        "Horário GPS": table.getColumn(columns[1].accessorKey as string).getIsVisible() ? dayjs(row.original.fixTime).format('DD/MM/YYYY HH:mm:ss') : null,
        "Horário Servidor": table.getColumn(columns[2].accessorKey as string).getIsVisible() ? dayjs(row.original.serverTime).format('DD/MM/YYYY HH:mm:ss') : null,
        Latitude: table.getColumn(columns[3].accessorKey as string).getIsVisible() ? `${row.original.latitude.toFixed(6)}°` : null,
        Longitude: table.getColumn(columns[4].accessorKey as string).getIsVisible() ? `${row.original.longitude.toFixed(6)}°` : null,
        Velocidade: table.getColumn(columns[5].accessorKey as string).getIsVisible() ? `${speedFromKnots(row.original.speed, 'kmh')} km/h` : null,
        "Endereço": table.getColumn(columns[6].accessorKey as string).getIsVisible() ? row.original.address : null,
        "Satélites": table.getColumn(columns[7].accessorKey as string).getIsVisible() ? (row.original.attributes.sat ?? 'Sem satélite') : null,
      }
    });
    const csvConfig = mkConfig({
      fieldSeparator: ';',
      decimalSeparator: '.',
      useKeysAsHeaders: false,
      filename: 'Posições',
      columnHeaders: columnHeaders
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportPdfRows = (table: MRT_TableInstance<Position>) => {
    const doc = new jsPDF();
    let rows = table.getFilteredRowModel().rows;
    const tableHeaders = columns.map((c) => table.getColumn(c.accessorKey as string).getIsVisible() ? c.header : null);
    const tableData = rows.map((row) => {
      return [
        table.getColumn(columns[0].accessorKey as string).getIsVisible() ? `${devices[row.original.deviceId].attributes.placa} - ${devices[row.original.deviceId].name}` : null,
        table.getColumn(columns[1].accessorKey as string).getIsVisible() ? dayjs(row.original.fixTime).format('DD/MM/YYYY HH:mm:ss') : null,
        table.getColumn(columns[2].accessorKey as string).getIsVisible() ? dayjs(row.original.deviceTime).format('DD/MM/YYYY HH:mm:ss') : null,
        // table.getColumn(columns[2].accessorKey as string).getIsVisible() ? row.original.fixTime : null,
        // table.getColumn(columns[3].accessorKey as string).getIsVisible() ? row.original.serverTime : null,
        table.getColumn(columns[3].accessorKey as string).getIsVisible() ? `${row.original.latitude.toFixed(6)}°` : null,
        table.getColumn(columns[4].accessorKey as string).getIsVisible() ? `${row.original.longitude.toFixed(6)}°` : null,
        table.getColumn(columns[5].accessorKey as string).getIsVisible() ? `${speedFromKnots(row.original.speed, 'kmh')} km/h` : null,
        table.getColumn(columns[6].accessorKey as string).getIsVisible() ? row.original.address : null,
        table.getColumn(columns[7].accessorKey as string).getIsVisible() ? (row.original.attributes.sat ?? 'Sem satélite') : null,
        //table.getColumn(columns[7].accessorKey as string).getIsVisible() ? (row.original.position && row.original.position.valid ? 'Com GPS' : 'Sem GPS') : null,
        //table.getColumn(columns[8].accessorKey as string).getIsVisible() ? getTimeDiff(row.original.lastUpdate, true) as string : null,
      ]
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save(`Posições.pdf`);
  };

  //optionally access the underlying virtualizer instance
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setData(positions);
      setIsLoading(false);
    }
  }, [positions]);

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  return (
    <TableContainer>
      <MaterialReactTable
        defaultColumn={{
          maxSize: 400,
          minSize: 20,
          size: 120, //default size is usually 180
        }}
        columns={columns}
        data={data}
        localization={MRT_Localization_PT_BR}
        //enableColumnOrdering={false}
        enableColumnDragging={false}
        enableGlobalFilter
        enablePagination
        positionPagination='bottom'
        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
        //state={{ pagination }} //pass the pagination state to the table
        initialState={{ density: 'comfortable' }}
        enableDensityToggle={false}

        //vitualization props
        muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 11.5rem)' } }}
        enableRowVirtualization
        onSortingChange={setSorting}
        state={{ pagination, isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
        rowVirtualizerOptions={{ overscan: 5 }} //optionally customize the row virtualizer
        columnVirtualizerOptions={{ overscan: 2 }} //optionally customize the column virtualizer

        renderTopToolbarCustomActions={({ table }) => 
          <TableTopToolbarAction
            addAction={undefined}
            exportPdfAction={table.getPrePaginationRowModel().rows.length !== 0 ?
              () => handleExportPdfRows(table) : undefined}
            exportCsvAction={table.getPrePaginationRowModel().rows.length !== 0 ?
              () => handleExportCsvRows(table) : undefined}
          />
        }

        editDisplayMode='modal' //default
        enableColumnOrdering
      />
    </TableContainer>
  );
});

export default PositionTable;