import { BaseResponseType, CreateNotification, UpdateNotification, TestNotification } from "../@types/Responses";
import { deleteOptions, getOptions, postOptions, putOptions } from "./common";

const endpoint = `${process.env.REACT_APP_API_FOLDER}/notifications`;

const fetchNotifications = async () : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, getOptions());
  return response.json();
}

const fetchNotificationsByUserId = async (userId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}?userId=${userId}`, getOptions());
  return response.json();
}

const fetchNotificationsByDeviceId = async (deviceId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}?deviceId=${deviceId}`, getOptions());
  return response.json();
}

const createNotification = async (user: CreateNotification) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, postOptions(JSON.stringify(user)));
  return response.json();
}

const deleteNotification = async (notificationId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/${notificationId}`, deleteOptions());
  return response.json();
}

const updateNotificationById = async (notification: UpdateNotification) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/${notification.id}`, putOptions(JSON.stringify(notification)));
  return response.json();
}

const sendWebNotificationTest = async (notification: TestNotification) : Promise<BaseResponseType> => {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/test/web`, postOptions(JSON.stringify(notification)));
  return response.json();
}

const sendFirebaseNotificationTest = async (notification: TestNotification) : Promise<BaseResponseType> => {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/test/firebase`, postOptions(JSON.stringify(notification)));
  return response.json();
}

export const notification = {
  fetchNotifications,
  fetchNotificationsByUserId,
  fetchNotificationsByDeviceId,
  createNotification,
  deleteNotification,
  updateNotificationById,
  sendWebNotificationTest,
  sendFirebaseNotificationTest
};