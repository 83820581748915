import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable, MRT_SortingState, MRT_Virtualizer } from 'material-react-table';
import type { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { GroupPayrollTableProps, GroupPayroll } from '../../@types/Props';
import TableTopToolbarAction from '../Common/TableTopToolbarAction';
import TableContainer from '../Common/TableContainer';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import dayjs from 'dayjs';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const GroupPayrollTable = memo(({groupsPayroll, actions}: GroupPayrollTableProps) => {
  const [data, setData] = useState<GroupPayroll[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'groupName', desc: false, }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  const columns = useMemo<MRT_ColumnDef<GroupPayroll>[]>(
    () => [
      {
        accessorKey: 'groupName',
        header: 'Nome',
        size: 55,
      },
      {
        accessorKey: 'totalDevices',
        header: 'Total',
        size: 55,
      },
      {
        accessorKey: 'activeDevices',
        header: 'Ativos',
        size: 55,
      },
      {
        accessorKey: 'inactiveDevices',
        header: 'Inativos',
        size: 55,
      },
      {
        accessorKey: 'totalToPay',
        accessorFn: (row) => `R$ ${row.totalToPay.toFixed(2)}`.replace('.', ','),
        header: 'Valor a receber',
        size: 50,
      },
    ],
    [],
  );

  const handleExportCsvRows = (table: MRT_TableInstance<GroupPayroll>) => {
    let rows = table.getFilteredRowModel().rows
    let columnHeaders = new Array<string>();
    rows[0].getAllCells().forEach(cel => {
      if (cel.column.getIsVisible() && cel.column.columnDef.header !== "Actions")
        columnHeaders.push(cel.column.columnDef.header as string)
    })

    const rowData = rows.map((row) => {
      return {
        Nome: table.getColumn(columns[0].accessorKey as string).getIsVisible() ? row.original.groupName : null,
        Total: table.getColumn(columns[1].accessorKey as string).getIsVisible() ? row.original.totalDevices : null,
        Ativos: table.getColumn(columns[2].accessorKey as string).getIsVisible() ? row.original.activeDevices : null,
        Inativos: table.getColumn(columns[3].accessorKey as string).getIsVisible() ? row.original.inactiveDevices : null,
        "Valor a receber": table.getColumn(columns[4].accessorKey as string).getIsVisible() ? `R$ ${row.original.totalToPay.toFixed(2)}`.replace('.', ',') : null,
      }
    });
    const now = dayjs().format('DD_MM_YYYY_HH_mm_ss')
    const csvConfig = mkConfig({
      fieldSeparator: ';',
      decimalSeparator: '.',
      useKeysAsHeaders: false,
      filename: `Fechamento_${now}`,
      columnHeaders: columnHeaders
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };
  
  const handleExportPdfRows = (table: MRT_TableInstance<GroupPayroll>) => {
    const doc = new jsPDF();
    let rows = table.getFilteredRowModel().rows;
    const tableHeaders = columns.map((c) => table.getColumn(c.accessorKey as string).getIsVisible() ? c.header : null);
    const tableData = rows.map((row) => {
      return [
        table.getColumn(columns[0].accessorKey as string).getIsVisible() ? row.original.groupName : null,
        table.getColumn(columns[1].accessorKey as string).getIsVisible() ? row.original.totalDevices : null,
        table.getColumn(columns[2].accessorKey as string).getIsVisible() ? row.original.activeDevices : null,
        table.getColumn(columns[3].accessorKey as string).getIsVisible() ? row.original.inactiveDevices : null,
        table.getColumn(columns[4].accessorKey as string).getIsVisible() ? `R$ ${row.original.totalToPay.toFixed(2)}`.replace('.', ',') : null,
      ]
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    const now = dayjs().format('DD_MM_YYYY_HH_mm_ss')
    doc.save(`Fechamento_${now}.pdf`);
  };

  //optionally access the underlying virtualizer instance
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setData(groupsPayroll);
      setIsLoading(false);
    }
  }, [groupsPayroll]);

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  return (
    <TableContainer>
      <MaterialReactTable
        defaultColumn={{
          maxSize: 400,
          minSize: 20,
          size: 120, //default size is usually 180
        }}
        columns={columns}
        data={data}
        localization={MRT_Localization_PT_BR}
        //enableColumnOrdering={false}
        enableColumnDragging={false}
        enableGlobalFilter
        enablePagination
        positionPagination='bottom'
        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
        //state={{ pagination }} //pass the pagination state to the table
        initialState={{ density: 'comfortable' }}
        enableDensityToggle={false}

        //vitualization props
        muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 11.5rem)' } }}
        enableRowVirtualization
        onSortingChange={setSorting}
        state={{ pagination, isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
        rowVirtualizerOptions={{ overscan: 5 }} //optionally customize the row virtualizer
        columnVirtualizerOptions={{ overscan: 2 }} //optionally customize the column virtualizer

        renderTopToolbarCustomActions={({ table }) => 
          <TableTopToolbarAction
            addAction={undefined}
            exportPdfAction={table.getPrePaginationRowModel().rows.length !== 0 ?
              () => handleExportPdfRows(table) : undefined}
            exportCsvAction={table.getPrePaginationRowModel().rows.length !== 0 ?
              () => handleExportCsvRows(table) : undefined}
          />
        }

        editDisplayMode='modal' //default
        enableColumnOrdering
      />
    </TableContainer>
  );
});

export default GroupPayrollTable;