import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, connect, batch } from 'react-redux';

import { deviceActions, eventActions, RootState, sessionActions } from './store';
import { api } from './api/api';
import { useDeviceContext } from './contexts/DeviceContext';
import { useSnackbarContext } from './contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';
import { EventValueType } from './@types/Props';
import { useTimer } from 'react-timer-hook';
import dayjs from 'dayjs';
import { speedFromKnots } from './util/util';
const POSITIONS_QUEUE_SIZE = 30;
const DEVICES_QUEUE_SIZE = 30;

type SocketEvent = {
  attributes: {
    speed?: number;
    speedLimit?: number;
    alarm?: string;
  };
  deviceId: number;
  eventTime: string;
  geofenceId: number;
  id: number;
  maintenanceId: number;
  positionId: number;
  type: string;
}
//import './assets/i18n/i18n'
// import { useEffectAsync } from './reactHelper';
// import { useTranslation } from './common/components/LocalizationProvider';
// import { snackBarDurationLongMs } from './common/util/duration';

// import alarm from './resources/alarm.mp3';
// import { eventsActions } from './store/events';
// import useFeatures from './common/util/useFeatures';
// import { useAttributePreference } from './common/util/preferences';

const logoutCode = 4000;
//const socketUrl = 'ws://frottus.com.br:8082/api/socket';

const SocketController = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const positionsEvent = useSelector((state: RootState) => state.event.positions);
  const positionsEventCount = Object.entries(positionsEvent).length;
  const devicesEvent = useSelector((state: RootState) => state.event.devices);
  const devicesEventCount = Object.entries(devicesEvent).length;
  const devices = useSelector((state: RootState) => state.device.items);
  const devicesCount = Object.entries(devices).length;
  const socketOnline = useSelector((state: RootState) => state.session.socketOnline);
  const user = useSelector((state: RootState) => state.session.user);
  const authenticated = useSelector((state: RootState) => !!state.session.user);

  const socketRef = useRef<WebSocket>();

  const { fetchDevicesAndPositions } = useDeviceContext();
  const { setOpen, setAlertMessage, setMessageSeverity } = useSnackbarContext();
  const [ events, setEvents ] = useState<SocketEvent[]>([]);
  const [ lastDeviceUpdate, setLastDeviceUpdate] = useState(dayjs().add(1, 'day'));
  const [ lastPositionUpdate, setLastPositionUpdate] = useState(dayjs().add(1, 'day'));

  // const soundEvents = useAttributePreference('soundEvents', '');
  // const soundAlarms = useAttributePreference('soundAlarms', 'sos');

  // const features = useFeatures();

  // const {
  //   sendMessage,
  //   sendJsonMessage,
  //   lastMessage,
  //   lastJsonMessage,
  //   readyState,
  //   getWebSocket,
  // } = useWebSocket(socketUrl, {
  //   onOpen: () => console.log('opened'),
  //   onMessage: (message) => console.log(message),
  //   onError: (message) => console.log(message),
  //   //Will attempt to reconnect on all close events, such as server shutting down
  //   shouldReconnect: (closeEvent) => true,
  //   reconnectAttempts: 10,
  //   reconnectInterval: 5000,
  // });

  const time = new Date();
  time.setSeconds(time.getSeconds() + 60);
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp: time });

  const restartTimer = () => {
    // Restarts to 30 seconds timer
    const time = new Date();
    time.setSeconds(time.getSeconds() + 60);
    restart(time)
  }

  const updateDevices = (forceUpdate = false) => {
    //console.log("devices: ", devicesEventCount);
    if (devicesEventCount >= DEVICES_QUEUE_SIZE || forceUpdate) {
      const devis = Object.entries(devicesEvent).map(x => x[1]);
      //console.log("Updating devices...");
      batch(() => {
        dispatch(deviceActions.update(devis));
        dispatch(eventActions.clearDevices());
      });
      setLastDeviceUpdate(dayjs());
    }
  }

  const updatePositions = (forceUpdate = false) => {
    //console.log("positions: ", positionsEventCount);
    if (positionsEventCount >= POSITIONS_QUEUE_SIZE || forceUpdate) {
      const posis = Object.entries(positionsEvent).map(x => x[1]);
      //console.log("Updating positions...");
      batch(() => {
        dispatch(deviceActions.updatePositions(posis));
        dispatch(eventActions.clearPositions());
      });
      setLastPositionUpdate(dayjs());
    }
  }

  useEffect(() => {
    let thirtySecondsBefore = dayjs().add(-30, 'second');
    let moreThan30SecondsSinceLastUpdate = thirtySecondsBefore.isAfter(lastDeviceUpdate) || thirtySecondsBefore.isAfter(lastPositionUpdate);
    //console.log("Verifying updates...", isRunning, socketOnline);
    //console.log(thirtySecondsBefore.format(), lastDeviceUpdate.format(), lastPositionUpdate.format());

    if (!isRunning && !socketOnline) {
      connectSocket();
      restartTimer();
    }
    if (socketOnline && moreThan30SecondsSinceLastUpdate) {
      //console.log("30 seconds since last update. Forcing update...")
      updateDevices(true);
      updatePositions(true);
    }
  }, [isRunning, socketOnline, devicesEvent, positionsEvent]);

  const connectSocket = () => {
    //const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    // const socket = new WebSocket(`${protocol}//${window.location.host}/api/socket`);
    // const socket = new WebSocket(`${protocol}//${window.location.host}/api/socket`);
    //const socket = new WebSocket(`${protocol}//${process.env.REACT_APP_WEBSOCKET_URL_NAME}/api/socket`);
    const socket = new WebSocket(`wss://${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_API_FOLDER}/socket`);
    
    socketRef.current = socket;

    socket.onopen = () => {
      //console.log('socket opened')
      dispatch(sessionActions.updateSocket(true));
    };

    socket.onclose = async (event) => {
      dispatch(sessionActions.updateSocket(false));
      if (event.code !== logoutCode && user !== null) {
        try {
          const devicesResponse = await api.device.fetchDevices();
          if (devicesResponse.success) {
            dispatch(deviceActions.update(devicesResponse.content));
          }
          const positionsResponse = await api.fetchPositions();
          if (positionsResponse.success) {
            dispatch(deviceActions.updatePositions(positionsResponse.content));
          }
        } catch (error) {
          // ignore errors
        }
        setTimeout(() => connectSocket(), 60000);
      }
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.devices) {
        for (let i = 0; i < data.devices.length; i++) {
          const device = data.devices[i];
          if (device !== null && device !== undefined)
            dispatch(eventActions.addDevice(device));
        }
      }
      if (data.positions) {
        for (let i = 0; i < data.positions.length; i++) {
          const position = data.positions[i];
          if (position !== null && position !== undefined)
            dispatch(eventActions.addPosition(position));
        }
      }
      if (data.events) {
        setEvents(data.events);
        if (data.events[0].type === 'alarm' && data.events[0].attributes.alarm !== 'powerCut')
          console.log(data.events[0])
      }
    };

    socket.onerror = (event) => {
      console.log(event);
    };
  };

  useEffect(() => {
    if (!socketOnline && user !== null) {
      fetchDevicesAndPositions(user.id, connectSocket)
    }

    return () => {
      const socket = socketRef.current;
      if (socket) {
        socket.close(logoutCode);
        dispatch(sessionActions.updateSocket(false));
      }
    };
  }, []);

  useEffect(() => {
    events.forEach(event => {
      const device = devices[event.deviceId];
      //console.log(event, device, devices)
      if (event !== null && event !== undefined && device !== null && device !== undefined) {
        const eventValueType = event.type as EventValueType
        if (eventValueType === 'deviceOverspeed')
          setAlertMessage(`Ultrapassou o limite de velocidade de ${speedFromKnots(event.attributes?.speedLimit,'kmh')} km/h (${speedFromKnots(event.attributes?.speed,'kmh')} km/h) - ${device.attributes?.placa}`);
        else if (eventValueType === 'alarm')
          setAlertMessage(`${t(eventValueType)} (${t(event.attributes?.alarm as EventValueType)}) - ${device.attributes?.placa}`);
        else
          setAlertMessage(`${t(eventValueType)} - ${device.attributes?.placa}`);

        setMessageSeverity(eventValueType === 'alarm' || eventValueType === 'deviceOverspeed' ? 'warning' : 'info');
        setOpen(true);
      }
    })
  }, [events]);

  useEffect(() => {
    updatePositions();
  }, [positionsEvent]);

  useEffect(() => {
    updateDevices();
  }, [devicesEvent]);

  // useEffect(() => {
  //   //console.log("SocketController");
  //   // if (authenticated) {
  //     //console.log("authenticated");
  //     // const response = await fetch('/api/devices');
  //     // if (response.ok) {
  //     //   dispatch(devicesActions.refresh(await response.json()));
  //     // } else {
  //     //   throw Error(await response.text());
  //     // }
  //     connectSocket();
  //     // return () => {
  //     //   const socket = socketRef.current;
  //     //   if (socket) {
  //     //     socket.close(logoutCode);
  //     //   }
  //     // };
  //   // }
  //   // return null;
  // }, [authenticated]);

  // useEffectAsync(async () => {
  //   if (authenticated) {
  //     const response = await fetch('/api/devices');
  //     if (response.ok) {
  //       dispatch(devicesActions.refresh(await response.json()));
  //     } else {
  //       throw Error(await response.text());
  //     }
  //     connectSocket();
  //     return () => {
  //       const socket = socketRef.current;
  //       if (socket) {
  //         socket.close(logoutCode);
  //       }
  //     };
  //   }
  //   return null;
  // }, [authenticated]);

  // useEffect(() => {
  //   setNotifications(events.map((event) => ({
  //     id: event.id,
  //     message: event.attributes.message,
  //     show: true,
  //   })));
  // }, [events, devices, t]);

  // useEffect(() => {
  //   events.forEach((event) => {
  //     if (soundEvents.includes(event.type) || (event.type === 'alarm' && soundAlarms.includes(event.attributes.alarm))) {
  //       new Audio(alarm).play();
  //     }
  //   });
  // }, [events, soundEvents, soundAlarms]);

  return (
    <>
      {/* {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open={notification.show}
          message={notification.message}
          autoHideDuration={snackBarDurationLongMs}
          onClose={() => setEvents(events.filter((e) => e.id !== notification.id))}
        />
      ))} */}
    </>
  );
};

export default connect()(SocketController);
