import { BaseResponseType, CreateGeofence, UpdateNotification } from "../@types/Responses";
import { deleteOptions, getOptions, postOptions, putOptions } from "./common";

const endpoint = `${process.env.REACT_APP_API_FOLDER}/geofences`;

const fetchGeofences = async () : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, getOptions());
  return response.json();
}

// const fetchGeofencesByUserId = async (userId: number) : Promise<BaseResponseType>=> {
//   const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}?userId=${userId}`, getOptions());
//   return response.json();
// }

const fetchGeofencesByDeviceId = async (deviceId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}?deviceId=${deviceId}`, getOptions());
  return response.json();
}

const createGeofence = async (geofence: CreateGeofence) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, postOptions(JSON.stringify(geofence)));
  return response.json();
}

const deleteGeofence = async (geofenceId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/${geofenceId}`, deleteOptions());
  return response.json();
}

// const updateGeofenceById = async (notification: UpdateNotification) : Promise<BaseResponseType>=> {
//   const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/${notification.id}`, putOptions(JSON.stringify(notification)));
//   return response.json();
// }

export const geofence = {
  fetchGeofences,
  // fetchGeofencesByUserId,
  fetchGeofencesByDeviceId,
  createGeofence,
  deleteGeofence,
  // updateNotificationById,
};