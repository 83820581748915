import { BaseResponseType } from "../@types/Responses";
import { getOptions } from "./common";

const endpoint = `${process.env.REACT_APP_API_FOLDER}/reports`;

const fetchPositionsByDeviceId = async (deviceId: number, from: string, to: string) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/positions?deviceId=${deviceId}&from=${from}&to=${to}`, getOptions());
  return response.json();
}

const fetchLastUpdateByUserId = async (userId: number, hours: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/lastUpdate?userId=${userId}&hours=${hours}`, getOptions());
  return response.json();
}

const fetchNoGpsDevicesByUserId = async (userId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/noGps?userId=${userId}`, getOptions());
  return response.json();
}

const fetchTotalGroupDevicesByUserId = async (userId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/totalGroupDevices?userId=${userId}`, getOptions());
  return response.json();
}

const fetchGroupPayroll = async (userId: number, unitCost: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/groupPayroll?userId=${userId}&unitCost=${unitCost}`, getOptions());
  return response.json();
}

export const report = {
  fetchPositionsByDeviceId,
  fetchLastUpdateByUserId,
  fetchNoGpsDevicesByUserId,
  fetchTotalGroupDevicesByUserId,
  fetchGroupPayroll,
};