import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable, MRT_SortingState, MRT_Virtualizer } from 'material-react-table';
import type { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { Device } from '../../@types/Device';
import { DeviceTableProps } from '../../@types/Props';
import TableTopToolbarAction from '../Common/TableTopToolbarAction';
import TableContainer from '../Common/TableContainer';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { getTimeDiff } from '../../util/util';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const LastUpdateTable = memo(({devices, actions}: DeviceTableProps) => {
  const [data, setData] = useState<Device[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'lastUpdate', desc: false, }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  const columns = useMemo<MRT_ColumnDef<Device>[]>(
    () => [
      {
        accessorKey: 'name', //simple recommended way to define a column
        header: 'Nome',
        size: 55,
      },
      {
        accessorKey: 'model', //id required if you use accessorFn instead of accessorKey
        header: 'Rastreador',
        size: 50,
      },
      {
        accessorKey: 'uniqueId',
        header: 'Código Rastreador',
        size: 55,
      },
      {
        accessorKey: 'phone',
        header: 'Nº Chip Rastreador',
        size: 55,
      },
      {
        accessorKey: 'attributes.placa',
        header: 'Placa',
        size: 50,
      },
      {
        accessorFn: (row) => `${row.attributes.modelo} - ${row.attributes.ano}`,
        accessorKey: 'attributes.modelo',
        header: 'Modelo',
        size: 50,
      },
      {
        accessorKey: 'group.name',
        header: 'Grupo',
        size: 50,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50,
      },
      {
        accessorKey: 'lastUpdate',
        accessorFn: (row) => getTimeDiff(row.lastUpdate, true),
        header: 'Última Comunicação',
        size: 50,
      }
    ],
    [],
  );

  const handleExportCsvRows = (table: MRT_TableInstance<Device>) => {
    let rows = table.getFilteredRowModel().rows
    let columnHeaders = new Array<string>();
    rows[0].getAllCells().forEach(cel => {
      if (cel.column.getIsVisible() && cel.column.columnDef.header !== "Actions")
        columnHeaders.push(cel.column.columnDef.header as string)
    })

    const rowData = rows.map((row) => {
      return {
        Nome: table.getColumn(columns[0].accessorKey as string).getIsVisible() ? row.original.name : null,
        Rastreador: table.getColumn(columns[1].accessorKey as string).getIsVisible() ? row.original.model : null,
        "Código Rastreador": table.getColumn(columns[2].accessorKey as string).getIsVisible() ? row.original.uniqueId : null,
        "Nº Chip Rastreador": table.getColumn(columns[3].accessorKey as string).getIsVisible() ? row.original.phone : null,
        Placa: table.getColumn(columns[4].accessorKey as string).getIsVisible() ? row.original.attributes.placa : null,
        Modelo: table.getColumn(columns[5].accessorKey as string).getIsVisible() ? row.original.attributes.modelo : null,
        Grupo: table.getColumn(columns[6].accessorKey as string).getIsVisible() && row.original.group ? row.original.group.name : null,
        Status: table.getColumn(columns[7].accessorKey as string).getIsVisible() ? row.original.status : null,
        "Última Comunicação": table.getColumn(columns[8].accessorKey as string).getIsVisible() ? getTimeDiff(row.original.lastUpdate, true) as string : null,
      }
    });
    const csvConfig = mkConfig({
      fieldSeparator: ';',
      decimalSeparator: '.',
      useKeysAsHeaders: false,
      filename: 'Última Comunicação',
      columnHeaders: columnHeaders
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportPdfRows = (table: MRT_TableInstance<Device>) => {
    const doc = new jsPDF();
    let rows = table.getFilteredRowModel().rows;
    const tableHeaders = columns.map((c) => table.getColumn(c.accessorKey as string).getIsVisible() ? c.header : null);
    const tableData = rows.map((row) => {
      return [
        table.getColumn(columns[0].accessorKey as string).getIsVisible() ? row.original.name : null,
        table.getColumn(columns[1].accessorKey as string).getIsVisible() ? row.original.model : null,
        table.getColumn(columns[2].accessorKey as string).getIsVisible() ? row.original.uniqueId : null,
        table.getColumn(columns[3].accessorKey as string).getIsVisible() ? row.original.phone : null,
        table.getColumn(columns[4].accessorKey as string).getIsVisible() ? row.original.attributes.placa : null,
        table.getColumn(columns[5].accessorKey as string).getIsVisible() ? row.original.attributes.modelo : null,
        table.getColumn(columns[6].accessorKey as string).getIsVisible() && row.original.group ? row.original.group.name : null,
        table.getColumn(columns[7].accessorKey as string).getIsVisible() ? row.original.status : null,
        table.getColumn(columns[8].accessorKey as string).getIsVisible() ? getTimeDiff(row.original.lastUpdate, true) as string : null,
      ]
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('Última Comunicação.pdf');
  };

  //optionally access the underlying virtualizer instance
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setData(devices);
      setIsLoading(false);
    }
  }, [devices]);

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  return (
    <TableContainer>
      <MaterialReactTable
        defaultColumn={{
          maxSize: 400,
          minSize: 20,
          size: 120, //default size is usually 180
        }}
        columns={columns}
        data={data}
        localization={MRT_Localization_PT_BR}
        //enableColumnOrdering={false}
        enableColumnDragging={false}
        enableGlobalFilter
        enablePagination
        positionPagination='bottom'
        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
        //state={{ pagination }} //pass the pagination state to the table
        initialState={{ density: 'comfortable' }}
        enableDensityToggle={false}

        //vitualization props
        muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 11.5rem)' } }}
        enableRowVirtualization
        onSortingChange={setSorting}
        state={{ pagination, isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
        rowVirtualizerOptions={{ overscan: 5 }} //optionally customize the row virtualizer
        columnVirtualizerOptions={{ overscan: 2 }} //optionally customize the column virtualizer

        renderTopToolbarCustomActions={({ table }) => 
          <TableTopToolbarAction
            addAction={undefined}
            exportPdfAction={table.getPrePaginationRowModel().rows.length !== 0 ?
              () => handleExportPdfRows(table) : undefined}
            exportCsvAction={table.getPrePaginationRowModel().rows.length !== 0 ?
              () => handleExportCsvRows(table) : undefined}
          />
        }

        editDisplayMode='modal' //default
        enableColumnOrdering
      />
    </TableContainer>
  );
});

export default LastUpdateTable;