import { LoginResponseTYpe } from "../@types/Responses";
import { User } from "../@types/User";

const endpoint = `${process.env.REACT_APP_API_FOLDER}/sessions`;

const logInTraccar = async (email: string, password: string) : Promise<Response> => {
  const token = window.btoa(`${email}:${password}`);
  window.localStorage.setItem('token', token);
  
  const response = fetch(
    `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SERVER_NAME}${endpoint}?email=${email}&password=${password}`,
    {
      credentials: 'include',
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  return response;
}

const logIn = async (email: string, password: string) : Promise<LoginResponseTYpe> => {
  const body = {
    email: email,
    password: password,
  };

  const token = window.btoa(`${email}:${password}`);
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${token}`
    },
    body: JSON.stringify(body)
  };
  
  window.localStorage.setItem('token', token);
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, options);
  
  return response.json();
}

export const session = {
  logInTraccar,
  logIn,
}
// export const getSession = async () : Promise<any> => {
  
//   const token = window.localStorage.getItem('token');

//   const options = {
//     method: 'GET',
//     headers: {
//       // 'Content-Type': 'application/json',
//       'Authorization': `Basic ${token}`,
//       'withCredentials': true
//     },
//   };

//   const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://frottus.com.br:8082/api/session`, options);
  
//   return response.ok ? response.json() : null;
// }