import { BaseResponseType, CreateUser, UpdateUser, UploadFile } from "../@types/Responses";
import { deleteOptions, getOptions, postOptions, putOptions } from "./common";

const endpoint = `${process.env.REACT_APP_API_FOLDER}/users`;

const fetchUsersByUserId = async (userId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}?userId=${userId}`, getOptions());

  return response.json();
}

const fetchUsers = async () : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, getOptions());
  return response.json();
}

const updateUserById = async (user: UpdateUser) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/${user.id}`, putOptions(JSON.stringify(user)));
  return response.json();
}

const createUser = async (user: CreateUser) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, postOptions(JSON.stringify(user)));
  return response.json();
}

const deleteUser = async (userId: number) : Promise<BaseResponseType>=> {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/${userId}`, deleteOptions());
  return response.json();
}

const uploadBill = async (uploadFile: UploadFile) : Promise<BaseResponseType>=> {
  const url = 'http://localhost:5000/bills/upload';
  const formData = new FormData();
  formData.append('file', uploadFile.file);
  formData.append('fileName', uploadFile.file.name);
  const config = {
    method: 'POST',
    body: formData,
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  const response = await fetch(url, config);
  return response.json();
}

export const user = {
  fetchUsersByUserId,
  fetchUsers,
  updateUserById,
  createUser,
  deleteUser,
  uploadBill,
};